import React from "react";
import { useState, useContext } from "react";
import { useNavigate } from "react-router";

import styles from "./auth.module.css"; // Ensure this path is correct
import { AuthContext } from "../../context/AuthContext";

const AdminSignInComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { signIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const canContinue = username !== "" && password;

  const handleSubmit = (event) => {
    event.preventDefault();
    signIn(username, password)
      .then((userCredential) => {
        if (userCredential) {
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
        // Optionally, show the user an error message here
      });
  };

  return (
    <div className={styles.admin_sign_in}>
      <form action="" className={styles.form_main}>
        <img
          alt="logo"
          src="/images/ReplyAssist-New-Logo-Transparent.png"
          className={styles.logo_icon}
        ></img>
        <p className={styles.heading}>Admin Login</p>
        <div className={styles.inputContainer}>
          <svg
            className={styles.inputIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#2e2e2e"
            viewBox="0 0 16 16"
          >
            {/* SVG path remains the same */}
          </svg>
          <input
            type="text"
            className={styles.inputField}
            id="username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>

        <div className={styles.inputContainer}>
          <svg
            className={styles.inputIcon}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#2e2e2e"
            viewBox="0 0 16 16"
          >
            {/* SVG path remains the same */}
          </svg>
          <input
            type="password"
            className={styles.inputField}
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {canContinue ? (
          <div className={styles.button} onClick={handleSubmit}>
            Login
          </div>
        ) : (
          <div className={styles.button_inactive}>Login</div>
        )}
      </form>
    </div>
  );
};

export default AdminSignInComponent;
