import styles from "./landingnavbar.module.css";
import { useNavigate } from "react-router";

const Navbar = () => {
  const navigate = useNavigate();

  const navigateTo = (url) => {
    return navigate(url);
  };

  function handleJoinWaitlist() {
    navigate("/join-newsletter");
  }

  function goHome() {
    navigate("/");
  }

  const navigateToExternal = (url) => {
    window.location.href = url;
  };

  return (
    <div className={styles.landing_navbar}>
      <div className={styles.menu_items}>
        <div className={styles.logo_box} onClick={goHome}>
          <img
            src="/images/ReplyAssist-New-Logo-Transparent.png"
            alt="logo"
            className={styles.logo}
          ></img>
        </div>
        {/* <div className={styles.menu_options}>
          <p className={styles.menu_item}>Home</p>
          <p className={styles.menu_item}>Mission</p>
          <p className={styles.menu_item}>Pricing</p>
          <p className={styles.menu_item}>FAQ</p>
          <p className={styles.menu_item}>Support</p>
          <p className={styles.menu_item}>Testimonials</p>
        </div> */}
      </div>
      <div className={styles.button_options}>
        <div
          className={styles.login_button}
          onClick={() =>
            navigateToExternal("https://auth.replyassist.com/signin")
          }
        >
          <p className={styles.login_text}>Login</p>
        </div>
        <div className={styles.signup_button} onClick={handleJoinWaitlist}>
          <p>Join Free Newsletter</p>
        </div>
      </div>
    </div>
  );
};

export default function LandingNavbar(props) {
  return (
    <div>
      {/* <div className={styles.header_bar}>
        <p className={styles.header_text}>
          Expected official launch of ReplyAssist is October 2nd!
        </p>
      </div> */}
      <Navbar />
      <div className={styles.landing_page_layout}>{props.childComponent}</div>
    </div>
  );
}
