import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";

const PricingPage = () => {
  return (
    <>
      <LandingNavbar
        childComponent={<p>Pricing Page</p>}
        activeText="Pricing"
      />
    </>
  );
};

export default PricingPage;
