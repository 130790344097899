import axios from "axios";

function formatJson(jsonObj) {
  return JSON.stringify(jsonObj, null, 2);
}

const DEVELOPMENT_API_KEY = "RA-a7B4k9F0xZ3yQ1LmP8vTc5S2rHjWnE6o";
const PRODUCTION_API_KEY = "RA-w4M1qT6xY3pV7lK2rJ9hN5cF8gB4aZ3";

export async function TEST_CREATE_PROSPECT_ENDPOINT() {
  const API_KEY = PRODUCTION_API_KEY;
  const accountID = "33252";
  const prospectID = "3892fh3298fh2fb23f89325331b2f";

  const timeStampOne = new Date(Date.now() - 1000000).toISOString();
  const timeStampTwo = new Date(Date.now()).toISOString();

  const request = {
    accountID: accountID,
    prospectID: prospectID,
    messages: [
      {
        sender: accountID,
        content: "Hey! Looking forward to connecting with you!",
        time_stamp: timeStampOne,
      },
      {
        sender: prospectID,
        content: "Hello, glad to connect!",
        time_stamp: timeStampTwo,
      },
    ],
    prospectInfo: {
      name: "John Smith",
      position: "Banker",
    },
  };

  try {
    const response = await axios.post(
      "https://api.production.replyassist.com/create-prospect",
      request,
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
        },
      }
    );
    console.log("Response:", response.data);
    return formatJson(response.data);
  } catch (error) {
    console.error("Error:", error);
    if (error.response) {
      return formatJson(error.response);
    } else {
      return { error: "An unexpected error occurred" };
    }
  }
}

export async function TEST_UPDATE_PROSPECT_ENDPOINT() {
  const API_KEY = PRODUCTION_API_KEY;
  const accountID = "4BvZA0HNH8QmtzqfY2gawAipm7W924";
  const prospectID = "3892fh3298fh2fb23f89325331b2f";

  const request = {
    accountID: accountID,
    prospectID: prospectID,
    prospectInfo: {
      name: "James",
      position: "Lawyer",
    },
  };

  try {
    const response = await axios.put(
      "https://api.production.replyassist.com/update-prospect",
      request,
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
        },
      }
    );
    console.log("Response:", response.data);
    return formatJson(response.data);
  } catch (error) {
    console.error("Error:", error);
    if (error.response) {
      return formatJson(error.response);
    } else {
      return { error: "An unexpected error occurred" };
    }
  }
}

export async function TEST_CREATE_MULTIPLE_PROSPECTS_ENDPOINT() {
  const API_KEY = PRODUCTION_API_KEY;
  const accountID = "4BvZA0HNH8QmtzqfY2gawAipm7W924";
  const prospectID = "9wLlyv7O7LWFv4EO4o4BvZsA0H25NHqm1";
  const prospectIDTwo = "9wLlyv7O7L5WFv4EOo4B143vZsA0H255NHqm1";

  const timeStampOne = new Date(Date.now() - 1000000).toISOString();
  const timeStampTwo = new Date(Date.now()).toISOString();

  const request = {
    accountID: accountID,
    prospectsData: [
      {
        prospectID: prospectID,
        prospectInfo: {
          firstName: "John",
          lastName: "Smith",
          position: "Investment Banker",
        },
        messages: [
          {
            sender: accountID,
            content: "Hey! Looking forward to connecting with you!",
            time_stamp: timeStampOne,
          },
          {
            sender: prospectID,
            content: "Hello, glad to connect!",
            time_stamp: timeStampTwo,
          },
        ],
      },
      {
        prospectID: prospectIDTwo,
        prospectInfo: {
          firstName: "Tom",
          lastName: "Freeman",
          position: "Lawyer",
        },
        messages: [
          {
            sender: accountID,
            content: "Hey! How's the search for a financial advisor going!",
            time_stamp: timeStampOne,
          },
          {
            sender: prospectIDTwo,
            content: "Great! Thank's for asking.",
            time_stamp: timeStampTwo,
          },
        ],
      },
    ],
  };

  try {
    const response = await axios.post(
      "https://api.production.replyassist.com/create-multiple-prospects",
      request,
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
        },
      }
    );
    console.log("Response:", response.data);
    return formatJson(response.data);
  } catch (error) {
    console.error("Error:", error);
    if (error.response) {
      return formatJson(error.response);
    } else {
      return { error: "An unexpected error occurred" };
    }
  }
}

export async function TEST_CHATBOT_ENDPOINT() {
  const API_KEY = PRODUCTION_API_KEY;
  const conversationID = "HsYnHI59EVWyY3NZFAEB";

  const request = {
    conversationID: conversationID,
    newMessages: [
      // {
      //   sender: conversationID,
      //   content: "Hey! Looking forward to connecting with you!",
      //   timestamp: "2023-11-28T12:00:00Z",
      // },
      // {
      //   sender: conversationID,
      //   content: "Hello, glad to connect!",
      //   timestamp: "2023-11-28T12:00:00Z",
      // },
    ],
  };

  try {
    const response = await axios.post(
      "https://api.production.replyassist.com/chatbot",
      request,
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
        },
      }
    );
    console.log("Response:", response.data);
    return formatJson(response.data);
  } catch (error) {
    console.error("Error:", error);
    if (error.response) {
      return formatJson(error.response);
    } else {
      return { error: "An unexpected error occurred" };
    }
  }
}
