import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import App from "./App";

// import NotFound from "./pages/NotFound";

import AdminSignInPage from "./pages/Authentication/AdminSignInPage";

import HomePage from "./pages/Landing/HomePage";
import MissionPage from "./pages/Landing/MissionPage";
import PricingPage from "./pages/Landing/PricingPage";
import FAQPage from "./pages/Landing/FAQPage";
import SupportPage from "./pages/Landing/SupportPage";
import TestimonialsPage from "./pages/Landing/TestimonialsPage";
import PrivacyPolicyPage from "./pages/Landing/PrivacyPolicyPage";
import TermsOfService from "./pages/Landing/TermsOfService";

import AdminPage from "./pages/Admin/AdminPage";
import DashboardPage from "./pages/Portal/DashboardPage";
import CampaignsPage from "./pages/Portal/CampaignsPage";
import InboxPage from "./pages/Portal/InboxPage";
import FollowUpsPage from "./pages/Portal/FollowUpsPage";
import DealStagesPage from "./pages/Portal/DealStagesPage";
import ProspectsPage from "./pages/Portal/ProspectsPage";
import IntegrationsPage from "./pages/Portal/IntegrationsPage";
import SettingsPage from "./pages/Portal/SettingsPage";
import { AuthContext } from "./context/AuthContext";

//ADMIN PAGES
// import ChatbotTestingPage from "./pages/Admin/ChatbotTestingPage";
import WaitlistPage from "./pages/Landing/WaitlistPage";
import FeedbackPage from "./pages/Portal/FeedbackPage";

function PrivateRoute({ children }) {
  const { currentUser } = useContext(AuthContext);

  // Check for user data in localStorage
  const isUserAuthenticated = localStorage.getItem("authUser") || currentUser;

  return isUserAuthenticated ? children : <Navigate to="/signin" />;
}

function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* ROUTES FOR LANDING PAGE */}
          <Route path="/" element={<App childComp={<AdminSignInPage />} />} />
          <Route
            path="/joinwaitlist"
            element={<App childComp={<WaitlistPage />} />}
          />
          <Route
            path="/join-newsletter"
            element={<App childComp={<WaitlistPage />} />}
          />
          <Route path="/home" element={<App childComp={<HomePage />} />} />
          <Route
            path="/mission"
            element={<App childComp={<MissionPage />} />}
          />
          <Route
            path="/pricing"
            element={<App childComp={<PricingPage />} />}
          />
          <Route path="/faq" element={<App childComp={<FAQPage />} />} />
          <Route
            path="/support"
            element={<App childComp={<SupportPage />} />}
          />
          <Route
            path="/testimonials"
            element={<App childComp={<TestimonialsPage />} />}
          />
          <Route
            path="/privacy-policy"
            element={<App childComp={<PrivacyPolicyPage />} />}
          />

          <Route
            path="/terms-of-service"
            element={<App childComp={<TermsOfService />} />}
          />

          {/* ROUTES FOR SIGNING IN AND SIGNING UP  */}

          <Route
            path="/signin"
            element={<App childComp={<AdminSignInPage />} />}
          />

          {/* <Route exact element={<ProtectedRoutes />}> */}
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/users"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/chatbot-testing"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/fine-tuning"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/training-data"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/training-data/:id"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/prompt-engineering"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/context-injection"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/follow-up-detection"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/api-testing"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/settings"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <App childComp={<DashboardPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/campaigns"
            element={
              <PrivateRoute>
                <App childComp={<CampaignsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/inbox"
            element={
              <PrivateRoute>
                <App childComp={<InboxPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/followups"
            element={
              <PrivateRoute>
                <App childComp={<FollowUpsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/dealstages"
            element={
              <PrivateRoute>
                <App childComp={<DealStagesPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/prospects"
            element={
              <PrivateRoute>
                <App childComp={<ProspectsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/integrations"
            element={
              <PrivateRoute>
                <App childComp={<IntegrationsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/feedback"
            element={<App childComp={<FeedbackPage />} />}
          />
          {/* <Route
            path="/chatbottesting"
            element={<App childComp={<ChatbotTestingPage />} />}
          /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;
