import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import AdminSignInComponent from "../../components/auth_components/AdminSignInComponent";

const AdminSignInPage = () => {
  return (
    <>
      <AdminSignInComponent />
    </>
  );
};

export default AdminSignInPage;
